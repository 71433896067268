import React from 'react'
import cx from 'classnames'
import classes from 'styles/HeaderBar.module.scss'
import { SignOutButton } from '@/components/signin/SignOutButton'
import Image from 'next/image'
import { Tooltip, Avatar, Button } from '@fluentui/react-northstar'
import { useSession } from 'next-auth/react'
import Link from 'next/link'
import { SUPPORT_MAIL } from '@/constantsClient'
import { SafeAvatar } from '@/components/SafeAvatar'

interface IHeaderBarProps {
  showLogOutButton?: boolean
  className?: string
}

export const HeaderBar: React.FC<IHeaderBarProps> = ({
  showLogOutButton = true,
  className = '',
}) => {
  const session = useSession()
  const email = session.data?.user?.email
  const image = session.data?.user?.image
  const name = session.data?.user?.name
  return (
    <header className={cx(classes.header, className)}>
      <Link className="flex items-center cursor-pointer" href="/">
        <Image src="/logo.png" alt="App logo" width={36} height={36} />
        <h1 className={classes.appName}>Delayed Send</h1>
      </Link>
      {showLogOutButton && (
        <div className="flex items-center ">
          <Button
            text
            as="a"
            href={`mailto:${SUPPORT_MAIL}`}
            target="_blank"
            className="!text-white hover:bg-[rgb(55,63,150)] mr-3"
          >
            Send feedback
          </Button>
          {image && email && name && (
            <Tooltip
              content={`${name} (${email})`}
              trigger={<SafeAvatar image={{ src: image }} className="mr-4" />}
            />
          )}
          <SignOutButton />
        </div>
      )}
    </header>
  )
}
