import { FC } from 'react'
import { VerticalFeatureRow } from '@/components/features/VerticalFeatureRow'
import { Section } from '@/components/layout/Section'
import { HeaderBar } from '@/components/HeaderBar'
import { Footer } from '@/components/Footer'
import { Button } from '@fluentui/react-northstar'
import Link from 'next/link'

const Landing: FC = () => {
  return (
    <>
      <HeaderBar showLogOutButton={false} />
      <div className="bg-gray-100">
        <Section
          title="Send Microsoft Teams messages in the future"
          description="Schedule your message to be delivered at the right time. It's like a time machine, for Microsoft Teams."
        >
          <Link href="/app" passHref legacyBehavior>
            <Button as="a" className="text-2xl !px-8 !py-6 !mx-4">
              Dashboard
            </Button>
          </Link>
          <Button
            primary
            as="a"
            href="https://teams.microsoft.com/l/app/ae64c32a-0eab-47d9-91e4-b693a99f7fc3"
            target="_blank"
            className="text-2xl !px-8 !py-6 !mx-4"
          >
            Open in Teams
          </Button>
        </Section>
      </div>
      <Section>
        <VerticalFeatureRow
          title="Time your messages"
          description="If you want to say something to someone, but don't want to bother them at the moment, simply schedule the delayed message for the right time and the right chat. Lots of use cases!"
          image="/assets/images/feature.svg"
          imageAlt="Scheduling Microsoft Teams messages to be sent in the future is easy."
        />
        <VerticalFeatureRow
          title="Cancel or reschedule messages"
          description="If you change your mind and the message is no longer necessary, use the dashboard to change the message or its delivery time. Or simply to cancel it altogether."
          image="/assets/images/feature2.svg"
          imageAlt="If you change your mind, you can change the message."
          reverse
        />
        <VerticalFeatureRow
          title="Completely Free"
          description="DelayedSend is a free service by Appfluence, the makers of Priority Matrix. Basic service will remain free, and we may add power features at a premium."
          image="/assets/images/feature3.svg"
          imageAlt="Free delayed messaging for Microsoft Teams."
        />
      </Section>
      <Footer />
    </>
  )
}

export default Landing
