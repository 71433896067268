import { signOut } from 'next-auth/react'
import { Button } from '@fluentui/react-northstar'
import { FC } from 'react'
import { useTeamsSDK } from 'use-teams-sdk'
import { useRouter } from 'next/router'

export const SignOutButton: FC<{}> = () => {
  const router = useRouter()
  const teamsSdkPromise = useTeamsSDK()
  const onSignOut = async () => {
    try {
      const sdk = await teamsSdkPromise
      if (!sdk) {
        return signOut()
      }
      await sdk.authentication.authenticate({
        url: window.location.origin + '/teams_logout',
        width: 400,
        height: 400,
      })
      await router.replace(`/login?next=${router.asPath}`)
    } catch (e) {
      console.error(e)
    }
  }
  return <Button onClick={onSignOut}>Sign out</Button>
}
