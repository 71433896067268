import { useMemo } from 'react'

type SdkPromise = Promise<typeof import('@microsoft/teams-js') | undefined>

export const useTeamsSDK = (): SdkPromise => {
  return useMemo(() => {
    return import('@microsoft/teams-js')
      .then(async (sdk) => {
        if (!sdk.app.isInitialized()) {
          return sdk.app.initialize().then((_) => sdk)
        }
        return sdk
      })
      .catch((e) => undefined)
  }, [])
}
