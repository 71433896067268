import { useState } from 'react'
import { Avatar } from '@fluentui/react-northstar'
import { AvatarProps } from '@fluentui/react-northstar'

export const SafeAvatar = (props: AvatarProps) => {
  const [error, setError] = useState(false)
  if (!props.image) {
    return <Avatar {...props} />
  }
  const imageProps = typeof props.image === 'string' ? { src: props.image } : props.image
  const image = Object.assign({}, imageProps, { onError: () => setError(true) })
  return <Avatar {...props} image={error ? undefined : image} />
}
